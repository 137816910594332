import { http } from '@/utils/http'
export default {
    getOrderList(params: object) {
        // 获取订单列表
     return http({
            url:`/members/api/v1/orders`,
            method:'GET',
            params:params
        })
    },
    getOrderDetail(params: string){
        return http({
            url:`/members/api/v1/orders/${params}/`,
            method:'GET'
        })
    },
    getMemberIntegal(){
        // 查询用户余额
        return http({
            url:`/members/api/v1/integral`,
            method:'GET',
        })
    },
    getMemberAd(){
        // 获取用户地址
        return http({
            url:`/members/api/v1/address`,
            method:'get'
        })
    },
    cancelOrder(params: string){
        // 取消订单
        return http({
            url:`/members/api/v1/orders/${params}/_cancel`,
            method:'put'
        })
    },
    payOrder(params: object){
        // 支付订单
        return http({
            url:`/members/api/v1/orders/_pay`,
            method:'post',
            data:params
        })
    },
    ReceivingGoods(params: object){
        // 确认收获
        return http({
            url:`/members/api/v1/orders/${params['id']}/_confirm`,
            method:'put',
            params:{
                score:params['score']
            }
        })
    },
    refund(params){
        // 退货退款
        return http({
            url:`/members/api/v1/refund`,
            method:'post',
            data:params
        })
    }
}