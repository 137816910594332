





































































































import { Vue, Component, Provide } from 'vue-property-decorator'
import Nav from '@/components/header.vue'
import moment from 'moment'
import md5 from 'js-md5'
import serve from '@/serve/order'
import { Toast, Dialog } from 'vant'
@Component({
  components: {
    Nav,
  },
})
export default class OrderDetail extends Vue {
  orderDetail = {}
  pic = []
  memberInfo = {}
  memberAd = {}
  @Provide() address = ''
  @Provide() show = false
  @Provide() showKeyboard = false
  @Provide() password = ''
  @Provide() img = require('@/assets/img/goods.jpg')
  created() {
    this.getDetail()
    this.getMemberIntegal()
    this.getMemberAd()
  }
  async getDetail() {
    const res = await serve.getOrderDetail(this.$route.query.id.toString())
    if (res.data.code === 0) {
      const str = res.data.data.pictures.replace('blob', '')
      this.pic = str.split(',')
      // console.log(this.pic)
      this.orderDetail = res.data.data
    }
  }
  async getMemberAd() {
    const res = await serve.getMemberAd()
    if (this.$route.query.adId) {
      this.memberAd = res.data.data.map((item) => {
        if (item.id === this.$route.query.adId) {
          return item
        }
      })
    } else {
      if (res.data.data.length > 0) {
        this.memberAd = res.data.data[0]
      }
    }
  }
  async payOrder() {
    const data = {
      addressId: this.memberAd['id'],
      orders: this.$route.query.id,
      password: md5(this.password),
    }
    const res = await serve.payOrder(data)
    if (res.data.code === 0) {
      Toast.success('支付成功')
      this.getDetail()
    } else if (res.data.code) {
      Toast.fail(res.data.message)
    } else {
      Dialog.confirm({
        title: '消息',
        message: res.data.message + '请充值后支付',
      })
        .then(() => {
          // on confirm
          this.$router.push(`/recharge`)
        })
        .catch(() => {
          // on cancel
        })
    }
  }
  async getMemberIntegal() {
    const res = await serve.getMemberIntegal()
    this.memberInfo = res.data.data
  }
  private tiemFormat(val: string): string {
    return moment(val).format('YYYY-MM-DD HH:mm:ss')
  }
  private chooseAddress(): void {
    this.$router.push(`/chooseAddress?id=${this.$route.query.id}`)
  }
  private pay(): void {
    this.show = true
  }
  private onDelete(): void {
    this.password = this.password.slice(0, this.password.length - 1)
  }
  private onInput(key: string): void {
    this.password = (this.password + key).slice(0, 6)
  }
}
